<template lang="pug">
   .con(v-if="!$store.state.pcshow")
      img(src="../assets/img/img21.png")
      div(class="hprofile")
        .conh3 COMPANY PROFILE
        .conh4 公司简介
        img(src="../assets/img/img20.png")
        h5 中关村高新技术企业
        p 中科医创科技有限公司成立于2019年，坐落于北京CBD商圈，注册资金5000万元人民币。公司以CRO、PBM 、数字健康社群营销、三大主营业务构筑商业蓝图。公司以北京为中心向全国进行产业辐射，先后在上海、杭州、连云港等地成立分公司并在澳大利亚墨尔本成立海外医学办事处负责多国际临床试验业务。
        p 公司产研部门47人占比高达40%，自主开发并拥有知识产权系统3个,软件著作权8个，9类商标1个，其中数字 健康社群营销系统（彼仙草）拥有互联网电子商务及信息服务全牌照。
      .yj
        .conh3 CORPORATE CULTURE
        .conh4 企业文化
        img(src="../assets/img/img22.png")
        img(src="../assets/img/img23.png")
      .yj
        h4
          span 
          em 全国布局
          img(src="../assets/img/yilianGl19.png")
      .hpartner
        h3.conh3 COOPERATIVE PARTNER
        h4.conh4 合作伙伴
        .scro
          .imggroup.clearfix
            .li
              img(src="../assets/img/h5img30.png")
            .li
              img(src="../assets/img/h5img31.png")
            .li
              img(src="../assets/img/h5img32.png")
            .li
              img(src="../assets/img/h5img33.png")
            .li
              img(src="../assets/img/h5img34.png")
            .li
              img(src="../assets/img/h5img35.png")
            .li
              img(src="../assets/img/h5img36.png")
            .li
              img(src="../assets/img/h5img37.png")
            .li
              img(src="../assets/img/h5img38.png")
            .li
              img(src="../assets/img/h5img39.png")
            .li
              img(src="../assets/img/h5img40.png")
            .li
              img(src="../assets/img/h5img41.png")
            .li
              img(src="../assets/img/h5img42.png")
            .li
              img(src="../assets/img/h5img43.png")
            .li
              img(src="../assets/img/h5img44.png")
            .li
              img(src="../assets/img/h5img45.png")
            .li
              img(src="../assets/img/h5img46.png")
            .li
              img(src="../assets/img/h5img47.png")
            .li
              img(src="../assets/img/h5img48.png")
            .li
              img(src="../assets/img/h5img49.png")

   .about(v-else)
    img(src="../assets/img/about/img1.jpg")
    .min
      .left
        h2 公司概况 
        span COMPANY PROFILE
        p 中科医创科技有限公司成立于2019年，初期注册资金为5000万元，目前已经在北京、上海、杭州成立分公司。中科医创是一家专注于为新药研发提供临床试验全过程专业服务的合同研究组织，我们致力于提供优质的临床试验技术，进一步提升研发质量、缩短研发周期、提高研发效率、降低研发成本，与客户携手共赢。
        //- p 中科医创隶属于亿联体集团，有非常强大的综合实力。
      img(src="../assets/img/about/img16.png")
    .mins
      img(src="../assets/img/about/img2.png")  
      .left
        h2 企业文化 
        span CORPORATE CULTURE
        h3 我们的使命
        p 成长为最令人信赖的CRO公司
        h3 我们的愿景
        p 提高药品研发的效率与效益，用科技促进人类健康事业的发展
    .map
      h2 全国布局
      h4
      p 我司从成立以来，在国内重要城市进行全面布局，保证了项目的质量和进度。并且会进一步进行战略布局，覆盖国内各大省份。
      //- img(src="../assets/img/about/img.jpg")
      img(src="../assets/img/about/chnisImg.png")
    .foter
      h2 战略合作伙伴
      span cooperative  partner
      div.carousel
        .left(:style="{ 'left': calleft + 'px' }",:class="calleft==0?'test':''")
          div(v-for="(item,index) in img")
            img(:src="item")
</template> 

<script>
let siv;
export default{
  name: 'About',
  data() {
    return {
      calleft: 0,
      img: [
        require('../assets/img/about/img3.png'),
        require('../assets/img/about/img4.png'),
        require('../assets/img/about/img5.png'),
        require('../assets/img/about/img6.png'),
        require('../assets/img/about/img7.png'),
        require('../assets/img/about/img8.png'),
        require('../assets/img/about/img9.png'),
        require('../assets/img/about/img10.png'),
        require('../assets/img/about/img11.png'),
        require('../assets/img/about/img12.png'),
        require('../assets/img/about/img13.png'),
        require('../assets/img/about/img14.png'),
        require('../assets/img/about/img15.png')
      ]
    }
  },
  mounted(){
    this.carousel()
  },
  
  methods:{
    addMe(){
      let then = this
      let siv2 = setInterval(function(){
          then.calleft ++
          if(then.calleft==0){
            clearInterval(siv2)
            then.carousel()
          }   
        
      },10)
      
      siv2
    },
    
    carousel(){
      let then = this
      siv = setInterval(function(){
         then.calleft --
         if(then.calleft%902==0){
           let newImg=then.img;
           then.img=then.img.concat(newImg);
         }
         if(then.img.length>26){
           then.img.splice(13,0)
           
         }
      },10)
     
    }
  },
  beforeRouteLeave (to, from, next) {
    let that=this;
    if(from.path=="/about"){
        clearInterval(siv)
    }
    console.log('siv')
    console.log(siv)
   next();
  },
}
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px)
    .con
      width: 100%;
      background: #F5F5F5;
      >img
        display: block
        width:7.5rem;
        height: 3rem;
      
    .hprofile
        display block
        width calc( 100% - 0.36rem )
        padding-left 0.36rem
        background #F5F5F5
        padding-bottom 0.2rem

        img
          display: block
          width: 5rem
          height: 3rem
        
        h5
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0085D0;
          padding-top: 0.24rem;
          padding-bottom:0.2rem;
        
        p
          width: 6.86rem;
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.45rem;
          margin-bottom:0.1rem;
          word-break:break-all;
    .conh3
      padding-top: 0.4rem
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    .conh4
      font-size: 0.26rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      padding 0.15rem 0 0.24rem 0
    .yj
      display: block;
      width: calc( 100% - 0.35rem );
      background: #ffffff;
      padding-left:0.35rem;
      padding-bottom:0.15rem;
      >img
        display: block;
        width: 6.8rem;
        height: 1.2rem;
        margin-bottom: 0.2rem;
    .yj
      display: block;
      width: calc( 100% - 0.35rem );
      background: #ffffff;
      padding-left:0.35rem;
      border-top: 0.4rem solid #f5f5f5;
      // border-bottom: 0.4rem solid #f5f5f5;

      >h4
        font-size: 0.26rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: 0.39rem;
        padding-top:0.24rem;
        padding-bottom:0.32rem;

        span
          display: inline-block;
          width: 0.03rem;
          height: 0.23rem;
          background: #0085D0;
          margin-right:0.07rem;
        img
          display: block;
          width: 6.8rem;
          height: 5.01rem;
          padding-bottom:0.47rem;
    .hpartner
      display block
      width calc( 100% - 0.36rem )
      padding-left 0.36rem
      position: relative
      overflow: hidden;

      .scro
        overflow: scroll;
        .imggroup
          width:26.48rem;
          padding-bottom: 0.3rem;
          .li
            display flex
            width: 2.16rem;
            height: 1.3rem;
            float: left 
            margin-right 0.45rem
            align-items: center;
            justify-content: center;
            background: #fff;
            margin-bottom:0.16rem

            img
              display block
              width: 2.16rem;
              height: 1.3rem;
              margin:0 auto;

          .li:nth-child(10),.li:nth-child(20)
            margin-right 0.35rem

      .scro::-webkit-scrollbar
        display: none;
          
        
  @media screen and (min-width: 960px)
    .about
      padding-top:90px;
    .min
      display flex
      justify-content space-between
      width 1280px
      margin 125px auto 0
      .left
        h2
          width: 138px;
          height: 32px;
          font-size: 34px;
          color: #333333;
          line-height: 32px;
          display inline-block
          margin-top 69px
          border-left 3px solid #02B2B5
          padding-left 11px
          margin-bottom 15px
        span 
          display inline-block
          width: 141px;
          height: 13px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 39px;
          margin-left 16px
        p
          width 536px
          padding-left 14px
          margin-top 30px
          line-height: 30px;
          color: #666666;
          opacity 0.9
          font-size: 15px;
      img
        width 622px
    .mins
      display flex
      justify-content space-between
      width 1280px
      margin 0 auto
      .left
        margin-left 96px
        h2
          width: 138px;
          height: 32px;
          font-size: 34px;
          color: #333333;
          line-height: 32px;
          display inline-block
          margin-top 69px
          border-left 3px solid #02B2B5
          padding-left 11px
        span 
          display inline-block
          width: 181px;
          height: 13px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 39px;
          margin-left 16px
        h3
          color: #333333;
          line-height: 23px; 
          font-weight: 500;
          font-size: 24px;
          margin-top 64px
          padding-left 14px
        p
          padding-left 14px
          margin-top 27px
          line-height: 30px;
          color: #666666;
          opacity 0.9
          width 439px
          font-size: 20px;
      img
        width 722px
    .map
      width 1296px
      margin 169px auto 169px
      text-align center
      h2
        font-size: 30px;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
      h4
        width 42px
        height 5px
        background #02B2B5
        margin 41px auto 39px
      p
        line-height 15px
        font-size 15px
        font-weight: 400;
        color: #666666;
        opacity 0.9
        margin-bottom 103px
    .foter
      background url("../assets/img/about/img17.png")
      width 100%
      margin-bottom 162px
      height 478px
      h2 
        text-align center
        font-size: 32px;
        color: #333333;
        padding-top 131px
        line-height 32px
      span
        font-size: 16px;
        line-height 16px
        color #999
        text-align center
        display block
        margin 19px 0 64px
      .carousel
        width 1280px
        margin 0 auto
        overflow hidden
        position relative
        height 84px
        .left
          display flex
          position absolute
          left 0px
          top 0px
          img 
            margin-right 61px
</style>